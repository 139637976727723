import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import { ScrollContainer } from "../../components/ScrollContainer";
import { TabButton } from "../../components/TabButton";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";

export function InventorySubNav() {
  return (
    <ScrollContainer>
      <InventoryTabs />
    </ScrollContainer>
  );
}

export function InventoryTabs() {
  const [serviceZone] = useServiceZoneQueryParam();
  const { t } = useTranslation("errorBoard");

  const { state } = useErrorBoard();
  const inventorySummary = state.data.summary?.inventory;

  return (
    <>
      <TabButton
        path={routes.inventory.whipper(serviceZone)}
        activePath={routes.inventory.whipper()}
        amount={inventorySummary?.whipper ?? 0}
      >
        {t("inventoryTabs.whipper")}
      </TabButton>

      <TabButton
        path={routes.inventory.coffeeBeans(serviceZone)}
        activePath={routes.inventory.coffeeBeans()}
        amount={inventorySummary?.coffeeBeans ?? 0}
      >
        {t("inventoryTabs.coffeeBeans")}
      </TabButton>
      <TabButton
        path={routes.inventory.cups(serviceZone)}
        activePath={routes.inventory.cups()}
        amount={inventorySummary?.cups ?? 0}
      >
        {t("inventoryTabs.cups")}
      </TabButton>
      <TabButton
        path={routes.inventory.milkAndSugar(serviceZone)}
        activePath={routes.inventory.milkAndSugar()}
        amount={inventorySummary?.milkAndSugar ?? 0}
      >
        {t("inventoryTabs.milkAndSugar")}
      </TabButton>
    </>
  );
}

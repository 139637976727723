import {
  GetMaintenanceBoardIceErrorDocument,
  GetMaintenanceBoardIceErrorQuery,
  GetMaintenanceBoardIceErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getIceError({
  serviceZoneId, cursor, sort
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardIceErrorQuery,
    GetMaintenanceBoardIceErrorQueryVariables
  >(GetMaintenanceBoardIceErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort
  })();
}

import * as React from "react";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import { useHistory } from "react-router-dom";
import { useMachinesStore } from "../../useMachinesStore";
import { MachineModals } from "./modals";
import { DownloadDropdownMenu } from "./DownloadDropdownMenu";
import { MoreDropdownMenu } from "./MoreDropdownMenu";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import analytics from "utils/analytics";
import { OnboardingDropdownMenu } from "./OnboardingDropdownMenu";

export function HeaderCTA() {
  const [,setDisableMachineOverviewV2] = useLocalStorage("disable-machine-overview-v2", false);
  const { t } = useTranslation("machine");
  const { setEnable } = useMachinesStore();
  const history = useHistory();
  return (
    <>
      <div className="flex gap-4 mb-4 items-center">
        <div className="flex gap-4 items-center flex-1">
          <OnboardingDropdownMenu />
          <DownloadDropdownMenu />
          <MoreDropdownMenu />
        </div>
        <div className="flex items-center gap-2">
          <p className="text-navigation-variant font-kanit gap-2">
            {t("try_new_experience")}
          </p>
          <ToggleSwitch
            isOn={true}
            onClick={() => {
              setEnable(false);
              history.replace("/machines/monitor/overview");
              setDisableMachineOverviewV2(true);
              analytics.track({
                name: "click try new experience",
                properties: { location: "header", enable: false },
              });

            }}
          />
        </div>
      </div>
      <MachineModals />
    </>
  );
}

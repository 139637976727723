import {
  GetMaintenanceBoardSodaErrorDocument,
  GetMaintenanceBoardSodaErrorQuery,
  GetMaintenanceBoardSodaErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getSodaError({
  serviceZoneId, cursor, sort
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardSodaErrorQuery,
    GetMaintenanceBoardSodaErrorQueryVariables
  >(GetMaintenanceBoardSodaErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort
  })();
}

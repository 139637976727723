import {
  GetMaintenanceBoardMachineErrorDocument,
  GetMaintenanceBoardMachineErrorQuery,
  GetMaintenanceBoardMachineErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getMachineUnsellableError({
  serviceZoneId,
  cursor,
  sort,
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardMachineErrorQuery,
    GetMaintenanceBoardMachineErrorQueryVariables
  >(GetMaintenanceBoardMachineErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort,
  })();
}

import {
  GetMaintenanceBoardMilkSugarErrorDocument,
  GetMaintenanceBoardMilkSugarErrorQuery,
  GetMaintenanceBoardMilkSugarErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getMilkAndSugar({
  serviceZoneId,
  cursor,
  sort,
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardMilkSugarErrorQuery,
    GetMaintenanceBoardMilkSugarErrorQueryVariables
  >(GetMaintenanceBoardMilkSugarErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort,
  })();
}

import {
  GetMaintenanceBoardOfflineErrorDocument,
  GetMaintenanceBoardOfflineErrorQuery,
  GetMaintenanceBoardOfflineErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getOfflineError({
  serviceZoneId, cursor, sort
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;

}) {
  return fetcher<
    GetMaintenanceBoardOfflineErrorQuery,
    GetMaintenanceBoardOfflineErrorQueryVariables
  >(GetMaintenanceBoardOfflineErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort
  })();
}

import {
  GetMaintenanceBoardIceRateErrorDocument,
  GetMaintenanceBoardIceRateErrorQuery,
  GetMaintenanceBoardIceRateErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getIceRateLow({
  serviceZoneId, cursor, sort
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardIceRateErrorQuery,
    GetMaintenanceBoardIceRateErrorQueryVariables
  >(GetMaintenanceBoardIceRateErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort
  })();
}

import * as React from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../../routes";
import { useServiceZoneQueryParam } from "../hooks/useServiceZoneQueryParam";
import { useImmer } from "use-immer";
import { State, Data, Summary, ErrorOverviews } from "../types";
import * as errorBoardService from "../services";
import { fetchTableData } from "./fetchTableData";
import { useSortQueryParam } from "../hooks/useSortQueryParam";

export const ErrorBoardContext = React.createContext<{
  refresh: () => void;
  paginate: () => void;
  state: State;
}>(undefined);

export function ErrorBoardProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useLocation();
  const [serviceZoneId] = useServiceZoneQueryParam();
  const [sort] = useSortQueryParam();
  const [state, setState] = useImmer<State>({
    status: "loading",
    data: {
      lastUpdated: undefined,
      summary: undefined,
      table: undefined,
      cursor: undefined,
      errorOverviews: undefined,
    },
    previousLocation: pathname,
    previousServiceZoneId: serviceZoneId,
    previousSort: sort,
    error: null,
  });
  // When location changes, clear table but not summary and last updated to persist red dots on tab navigation
  if (pathname !== state.previousLocation) {
    setState((draft) => {
      draft.data.cursor = undefined;
      draft.data.table = undefined;
      draft.status = "loading";
      draft.previousLocation = pathname;
    });
  }
  if (
    sort.sortCol !== state.previousSort.sortCol ||
    sort.sortDir !== state.previousSort.sortDir
  ) {
    setState((draft) => {
      draft.data.cursor = undefined;
      draft.data.table = undefined;
      draft.status = "loading";
      draft.previousSort = sort;
    });
  }

  // When service zone changes, clear everything to reinitialize UI
  if (state.previousServiceZoneId !== serviceZoneId) {
    setState((draft) => {
      draft.data.cursor = undefined;
      draft.data.table = undefined;
      draft.data.lastUpdated = undefined;
      draft.data.summary = undefined;
      draft.data.errorOverviews = undefined;
      draft.status = "loading";
      draft.previousServiceZoneId = serviceZoneId;
    });
  }
  React.useEffect(() => {
    // Wait here till user navigates to a tab that can be queried
    if (!validRoutes.includes(pathname)) {
      setState((draft) => {
        draft.status = "invalid_url";
      });
      return;
    }

    // Closure scope variable to prevent race conditions
    let hasLocationChanged = false;

    async function fetchErrorBoardData() {
      try {
        const [summary, errorOverviews, tableInfo] = await Promise.all([
          getSummary(serviceZoneId),
          getErrorOverviews(serviceZoneId),
          fetchTableData({
            pathname,
            serviceZoneId,
            sortDir: sort.sortDir,
            sortCol: sort.sortCol,
          }),
        ]);
        // Do not set state if URL has changed, prioritize latest URL
        if (hasLocationChanged) {
          return;
        }
        setState((draft) => {
          draft.data.summary = summary;
          draft.data.errorOverviews = errorOverviews;
          draft.data.table = tableInfo.table;
          draft.data.cursor = tableInfo.cursor;
          draft.status = "success";
          draft.data.lastUpdated = new Date();
        });
      } catch (e) {
        // Do not set state if URL has changed, prioritize latest URL
        if (hasLocationChanged) {
          return;
        }
        console.error(e);
        setState((draft) => {
          draft.status = "error";
          draft.error = e;
        });
      }
    }

    fetchErrorBoardData();

    return () => {
      hasLocationChanged = true;
    };
  }, [pathname, serviceZoneId, sort.sortCol, sort.sortDir]);

  async function refresh() {
    // Only allows users to refresh when UI is not refreshing or not loading data
    if (state.status === "refreshing" || state.status === "loading") {
      return;
    }

    // Clear all data on screen
    setState((draft) => {
      draft.status = "refreshing";
      draft.data.cursor = undefined;
      draft.data.lastUpdated = undefined;
      draft.data.summary = undefined;
      draft.data.errorOverviews = undefined;
      draft.data.table = undefined;
    });

    try {
      const [summary, errorOverviews, tableInfo] = await Promise.all([
        getSummary(serviceZoneId),
        getErrorOverviews(serviceZoneId),
        fetchTableData({
          pathname,
          serviceZoneId,
          sortDir: sort.sortDir,
          sortCol: sort.sortCol,
        }),
      ]);
      setState((draft) => {
        // If user navigates to another tab, do not set state as user is no longer refreshing
        if (draft.status !== "refreshing") {
          return;
        }
        draft.data.summary = summary;
        draft.data.errorOverviews = errorOverviews;
        draft.data.table = tableInfo.table;
        draft.data.cursor = tableInfo.cursor;
        draft.status = "success";
        draft.data.lastUpdated = new Date();
      });
    } catch (e) {
      setState((draft) => {
        if (draft.status !== "refreshing") {
          return;
        }
        console.error(e);
        draft.status = "error";
        draft.error = e;
      });
    }
  }

  async function paginate() {
    // Only allow pagination if data is successfully loaded and cursor is available
    if (state.status !== "success" || state.data.cursor === undefined) {
      return;
    }
    setState((draft) => {
      draft.status = "paginating";
    });

    try {
      const [summary, errorOverviews, tableData] = await Promise.all([
        getSummary(serviceZoneId),
        getErrorOverviews(serviceZoneId),
        fetchTableData({
          pathname,
          serviceZoneId,
          cursor: state.data.cursor,
          sortDir: sort.sortDir,
          sortCol: sort.sortCol,
        }),
      ]);

      setState((draft) => {
        // If user navigates to another tab, do not set state as user is no longer paginating
        if (draft.status !== "paginating") {
          return;
        }

        draft.data.summary = summary;
        draft.data.errorOverviews = errorOverviews;

        if (draft.data.table.type !== tableData.table.type) {
          throw new Error("Invalid table type");
        }

        draft.data.table.data = [
          ...draft.data.table.data,
          ...tableData.table.data,
        ] as Data["table"]["data"];

        draft.data.cursor = tableData.cursor;
        draft.status = "success";
        draft.data.lastUpdated = new Date();
      });
    } catch (e) {
      setState((draft) => {
        if (draft.status !== "paginating") {
          return;
        }
        console.error(e);
        draft.status = "paginating-error";
        draft.error = e;
      });
    }
  }

  return (
    <ErrorBoardContext.Provider
      value={{
        refresh,
        state,
        paginate,
      }}
    >
      {children}
    </ErrorBoardContext.Provider>
  );
}

async function getErrorOverviews(
  serviceZoneId?: string
): Promise<ErrorOverviews> {
  const response = await errorBoardService.getErrorOverviews({
    serviceZoneId: serviceZoneId,
  });

  return {
    moduleScoreAmount: response.maintenanceOverview.moduleScore,
    drinkFail: {
      amount: response.maintenanceOverview.drinkFailure.amount,
      percentage: response.maintenanceOverview.drinkFailure.percentage,
    },
    softwareHardwareFail: {
      amount: response.maintenanceOverview.drinkSystemFailure.amount,
      percentage: response.maintenanceOverview.drinkSystemFailure.percentage,
    },
    inventoryFail: {
      amount: response.maintenanceOverview.drinkInventoryFailure.amount,
      percentage: response.maintenanceOverview.drinkInventoryFailure.percentage,
    },
    overallScore: {
      amount: response.maintenanceOverview.overallScore,
      status:
        response.maintenanceOverview.overallScore < 40
          ? "GOOD"
          : response.maintenanceOverview.overallScore < 70
            ? "NEUTRAL"
            : "BAD",
    },
    inventoryScore: response.maintenanceOverview.inventoryScore,
    frequentErrors: response.maintenanceOverview.frequentErrors.map(
      (frequentError) => ({
        errorCode: frequentError.errorCode,
        frequency: frequentError.count,
      })
    ),
  };
}

async function getSummary(serviceZoneId?: string): Promise<Summary> {
  const response = await errorBoardService.getSummary(serviceZoneId);
  return {
    majorTrouble: {
      machineUnsellable: response.machine.totalCount,
      outOfService: response.outOfService.totalCount,
      offline: response.offline.totalCount,
      iceError: response.ice.totalCount,
      coffeeError: response.coffee.totalCount,
      iceRateLow: response.iceRate.totalCount,
      sodaError: response.soda.totalCount,
      spiralUnsellable: response.spiral.totalCount,
    },
    inventory: {
      whipper: response.whipper.totalCount,
      milkAndSugar: response.milkSugar.totalCount,
      coffeeBeans: response.coffeeMaterial.totalCount,
      cups: response.cup.totalCount,
    },
    transactions: {
      aaNoTransaction30Mins: response.aaNoTransaction30Mins.totalCount,
      noTransaction2Hours: response.noTransaction2Hours.totalCount,
      noTransactionToday: response.noTransactionToday.totalCount,
      billAndCoin: response.billCoin.totalCount,
    },
  };
}

const validRoutes = [
  routes.majorTrouble.machineUnsellable(),
  routes.majorTrouble.outOfService(),
  routes.majorTrouble.offline(),
  routes.majorTrouble.iceError(),
  routes.majorTrouble.coffeeError(),
  routes.majorTrouble.iceRateLow(),
  routes.majorTrouble.sodaError(),
  routes.majorTrouble.spiralUnsellable(),
  routes.inventory.whipper(),
  routes.inventory.milkAndSugar(),
  routes.inventory.coffeeBeans(),
  routes.inventory.cups(),
  routes.transactions.aaNoTransaction30Mins(),
  routes.transactions.noTransaction2Hours(),
  routes.transactions.noTransactionToday(),
  routes.transactions.billAndCoin(),
];

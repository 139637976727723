import {
  GetMaintenanceBoardNoTransactionTodayDocument,
  GetMaintenanceBoardNoTransactionTodayQuery,
  GetMaintenanceBoardNoTransactionTodayQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getNoTransactionToday({
  serviceZoneId, cursor, sort
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardNoTransactionTodayQuery,
    GetMaintenanceBoardNoTransactionTodayQueryVariables
  >(GetMaintenanceBoardNoTransactionTodayDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort
  })();
}

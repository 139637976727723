import {
  GetMaintenanceBoardOutOfServiceErrorDocument,
  GetMaintenanceBoardOutOfServiceErrorQuery,
  GetMaintenanceBoardOutOfServiceErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getOutOfServiceError({
  serviceZoneId,
  cursor,
  sort,
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardOutOfServiceErrorQuery,
    GetMaintenanceBoardOutOfServiceErrorQueryVariables
  >(GetMaintenanceBoardOutOfServiceErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort,
  })();
}

import {
  GetMaintenanceBoardAaNoTransaction30MinsErrorDocument,
  GetMaintenanceBoardAaNoTransaction30MinsErrorQuery,
  GetMaintenanceBoardAaNoTransaction30MinsErrorQueryVariables,
  MaintenanceBoardSortInput,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getAaNoTransaction30Mins({
  serviceZoneId,
  cursor,
  sort,
}: {
  serviceZoneId?: string;
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardAaNoTransaction30MinsErrorQuery,
    GetMaintenanceBoardAaNoTransaction30MinsErrorQueryVariables
  >(GetMaintenanceBoardAaNoTransaction30MinsErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
    sort: sort,
  })();
}

import { useHistory, useLocation } from "react-router-dom";
import { SERVICE_ZONE_QUERY_PARAM } from "../../../routes";

export function useServiceZoneQueryParam() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let serviceZone = searchParams.get(SERVICE_ZONE_QUERY_PARAM);
  if (!serviceZone) {
    serviceZone = null;
  }

  const history = useHistory();

  function setServiceZone(id: string | null) {
    if (id) {
      const newSearchParams = new URLSearchParams();
      newSearchParams.set(SERVICE_ZONE_QUERY_PARAM, id);
      history.push({
        search: newSearchParams.toString(),
      });
    } else {
      history.push({
        search: "",
      });
    }
  }

  return [serviceZone, setServiceZone] as const;
}
